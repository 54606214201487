import { css } from "@emotion/css";
import { Outlet } from "react-router-dom";
import useAuth from "../hooks/AuthHook";
import NavLink from "../components/NavLink";
import AccountDropDown from "../components/AccountDropDown";

const menuItems = [
  { name: "2Tag", link: "/", isPrivate: false },
  { name: "Account", link: "/", isPrivate: false },
  { name: "Reports", link: "/", isPrivate: false },
];

const Navbar = () => {
  const { isLoggedIn } = useAuth();

  return (
    <>
      <nav>
        <div
          className={css`
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 1rem;
            padding: 1rem;
          `}>
          { <AccountDropDown />}
          {/* {isLoggedIn ? (
            <div key="Logout">
              <NavLink to="/logout">Logout</NavLink>
            </div>
          ) : (
            <div key="Login/SignUp">
              <NavLink to="/login">Login/SignUp</NavLink>
            </div>
          )} */}
        </div>
      </nav>
      <Outlet />
    </>
  );
};

export default Navbar;
