import './index.css';
import useAuth from './hooks/AuthHook';
import RootRouter from './routes/rootRouter';
import reportWebVitals from './reportWebVitals';
import ScrollToTop from './components/ScrollToTop';
import SpinnerWrapper from './components/SpinnerWrapper';
import { SnackbarProvider } from 'notistack';

const App = () => {
  const { isLoading } = useAuth();

  if (isLoading) return <SpinnerWrapper />;

  return (
    <SnackbarProvider 
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      autoHideDuration={3000}
    >
      <ScrollToTop>
        <RootRouter />
      </ScrollToTop>
    </SnackbarProvider>
  );
};

reportWebVitals();

export default App;